<template>
  <div class="base_layout">
    <h1>Metro数据看板</h1>

    <el-dialog v-model="sub_record_visible" title="订阅记录详情" :width="2500">
      <span v-if="loading_record">加载中...</span>
      <el-table row-key="id" default-expand-all :data="sub_record_table" v-else>
        <el-table-column property="transactionId" label="交易ID" />
        <el-table-column property="notificationType" label="订阅状态" />
        <el-table-column property="originalTransactionId" label="OID" />
        <el-table-column property="offerType" label="推介优惠" />
        <el-table-column property="priceAmountMicros" label="价格" />
        <el-table-column property="priceCurrencyCode" label="货币" />
        <el-table-column property="productId" label="当前套餐">
          <template #default="scope">
            <el-tag v-if="scope.row.productId == 'xyz.metrovpn.monthly'"
              >月套餐</el-tag
            >
            <el-tag
              type="success"
              v-if="scope.row.productId == 'xyz.metrovpn.quarterly'"
              >季套餐</el-tag
            >
            <el-tag
              type="danger"
              v-if="scope.row.productId == 'xyz.metrovpn.yearly'"
              >年套餐</el-tag
            >
            <el-tag
              type="warning"
              v-if="scope.row.productId == 'xyz.metrovpn.6months'"
              >半年套餐</el-tag
            >
            <el-tag
              type="info"
              v-if="scope.row.productId == 'xyz.metrovpn.2-year-pass'"
              >两年套餐</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column property="renew_productId" label="续订套餐">
          <template #default="scope">
            <el-tag v-if="scope.row.renew_productId == 'xyz.metrovpn.monthly'"
              >月套餐</el-tag
            >
            <el-tag
              type="success"
              v-if="scope.row.renew_productId == 'xyz.metrovpn.quarterly'"
              >季套餐</el-tag
            >
            <el-tag
              type="danger"
              v-if="scope.row.renew_productId == 'xyz.metrovpn.yearly'"
              >年套餐</el-tag
            >
            <el-tag
              type="warning"
              v-if="scope.row.renew_productId == 'xyz.metrovpn.6months'"
              >半年套餐</el-tag
            >
            <el-tag
              type="info"
              v-if="scope.row.productId == 'xyz.metrovpn.2-year-pass'"
              >两年套餐</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column property="purchaseDate" label="订阅时间" />
        <el-table-column property="expiresDate" label="过期时间" />
        <el-table-column property="createTime" label="订单生成时间" />
        <el-table-column property="updateTime" label="订单更新时间" />
      </el-table>
    </el-dialog>

    <el-row>
      <el-tabs type="border-card" style="width: 100%">
        <el-tab-pane
          v-if="get_table_view('新增设备/活跃设备表')"
          label="新增设备/活跃设备表"
        >
          <h3>新增设备/活跃设备表</h3>
          <p style="color: red">新增设备数无法根据国家查询</p>
          <el-row>
            <el-form
              :inline="true"
              :model="newDeviceForm"
              style="width: 100%"
              label-position="left"
            >
              <el-row justify="space-between">
                <el-col :span="6">
                  <el-form-item label="地区/国家" style="width:70%">
                    <el-select
                      v-model="newDeviceForm.mcc"
                      placeholder="全部国家/地区"
                      filterable
                      allow-create
                      clearable
                    >
                      <el-option
                        v-for="(item, index) in country_list"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="搜索词语" style="width:70%">
                    <el-select
                      v-model="newDeviceForm.site_id"
                      placeholder="选择搜索词语"
                      filterable
                      allow-create
                      clearable
                    >
                      <el-option-group
                        v-for="group in site_id_list"
                        :key="group.label"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="广告系列" style="width:70%">
                    <el-select
                      v-model="newDeviceForm.campaign_id"
                      placeholder="选择广告系列"
                      filterable
                      allow-create
                      clearable
                    >
                      <el-option-group
                        v-for="group in campaign_id_list"
                        :key="group.label"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6" >
                  <el-form-item label="平台" style="width:70%">
                    <el-select
                      v-model="newDeviceForm.platform"
                      placeholder="选择平台"
                      filterable
                      allow-create
                      clearable
                    >
                      <el-option
                        v-for="(item, index) in platform_list"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="时间范围">
                    <el-date-picker
                      v-model="newDeviceForm.date"
                      type="daterange"
                      unlink-panels
                      size="default"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :shortcuts="shortcuts"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      style="height: 32px"
                    />
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="query_new_device_table"
                      style="width: 180px"
                      >查询</el-button
                    >
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="flush_search_value"
                      style="width: 180px"
                      >刷新搜索参数</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-row>

          <el-tabs
            type="border-card"
            style="width: 100%"
            @tab-change="changeNewDeviceTabs"
            v-model="new_device_tab_index"
          >
            <el-tab-pane label="数据表格" name="0">
              <el-row>
                <el-table
                  :data="deviceTableData"
                  v-loading="loading"
                  element-loading-text="加载中..."
                >
                  <el-table-column prop="date" label="日期" align="center" />
                  <el-table-column
                    prop="new_device_num"
                    label="新增设备数"
                    align="center"
                  />
                  <el-table-column label="活跃设备数" align="center">
                    <el-table-column
                      prop="vip_device_num"
                      label="付费活跃"
                      align="center"
                    />
                    <el-table-column
                      prop="free_device_num"
                      label="试用活跃"
                      align="center"
                    />
                    <el-table-column
                      prop="vip_active_rate"
                      label="付费活跃占比"
                      align="center"
                    >
                      <template #default="scope">
                        {{ scope.row.vip_active_rate }}%
                      </template>
                    </el-table-column>
                  </el-table-column>
                </el-table>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="数据图表" name="1">
              <el-row id="new_device_line_chart"> </el-row>

              <el-row id="vip_active_rate_line_chart"> </el-row>

              <el-row id="sub_device_line_chart"> </el-row>
              <el-row id="free_device_line_chart"> </el-row>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>

        <el-tab-pane v-if="get_table_view('订阅详情表')" label="订阅详情表">
          <el-row>
            <el-col :span="4">总订单数:{{ sub_detail_data.total }}</el-col>
            <el-col :span="4">今日新增:{{ sub_detail_data.today }}</el-col>
            <el-col :span="4"
              >总月套餐:{{ sub_detail_data.total_month }}</el-col
            >
            <el-col :span="4"
              >总季套餐:{{ sub_detail_data.total_season }}</el-col
            >
            <el-col :span="4"
              >半年套餐:{{ sub_detail_data.total_6month }}</el-col
            >
            <el-col :span="4">总年套餐:{{ sub_detail_data.total_year }}</el-col>
          </el-row>

          <el-row :gutter="16">
            <el-col :span="4">
              <el-form-item label="搜索词语">
                <el-select
                  v-model="subUserForm.site_id"
                  placeholder="选择搜索关键词"
                  filterable
                  allow-create
                  clearable
                >
                  <el-option-group
                    v-for="group in site_id_list"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="广告系列">
                <el-select
                  v-model="subUserForm.campaign_id"
                  placeholder="选择广告系列"
                  filterable
                  allow-create
                  clearable
                >
                  <el-option-group
                    v-for="group in campaign_id_list"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label="日期类型">
                <el-select
                  v-model="subUserForm.is_firstSub"
                  placeholder="选择日期类型"
                  filterable
                  allow-create
                  clearable
                >
                  <el-option
                    v-for="item in firstSub_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="隐藏字段">
                <el-select
                  v-model="hidden_values"
                  placeholder="选择隐藏的关键词"
                  filterable
                  allow-create
                  clearable
                  multiple
                  @change="changeValue"
                >
                  <el-option
                    v-for="item in hidden_value_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="货币">
                <el-select
                  v-model="subUserForm.priceCurrencyCode"
                  placeholder="选择支付货币"
                  filterable
                  allow-create
                  clearable
                >
                  <el-option
                    v-for="item in priceCurrencyCode_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-form-item label="订阅平台">
              <el-select
                v-model="sub_platform_name"
                filterable
                placeholder="选择订阅平台"
                clearable
                style="width: 200px"
              >
                <el-option
                  v-for="item in sub_platform_list"
                  :key="item.tag"
                  :label="item.tag"
                  :value="item.tag"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-row>

          <el-row :gutter="16">
            <el-col :span="4">
              <el-form-item label="用户UID">
                <el-input
                  v-model="subUserForm.uid"
                  placeholder="请输入用户的uid"
                  style="width: 214px"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="订阅套餐">
                <el-select
                  v-model="subUserForm.productId"
                  placeholder="选择订阅套餐"
                  filterable
                  allow-create
                  clearable
                >
                  <el-option
                    v-for="item in sub_plan_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label="订阅状态">
                <el-select
                  v-model="subUserForm.sub_status"
                  placeholder="选择订阅状态"
                  filterable
                  allow-create
                  clearable
                >
                  <el-option
                    v-for="item in sub_status_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="语言地区">
                <el-select
                  v-model="subUserForm.lang"
                  placeholder="选择语言地区"
                  filterable
                  allow-create
                  clearable
                >
                  <el-option
                    v-for="item in lang_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="国家">
                <el-select
                  v-model="subUserForm.country"
                  placeholder="选择国家"
                  filterable
                  allow-create
                  clearable
                >
                  <el-option
                    v-for="item in country_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="4">
              <el-form-item label="支付来源">
                <el-select
                  v-model="subUserForm.pay_source"
                  placeholder="支付来源"
                  filterable
                  allow-create
                  clearable
                >
                  <el-option
                    v-for="item in pay_source_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col> -->
          </el-row>

          <el-row :gutter="16">
            <el-col :span="4">
              <el-form-item label="是否试用">
                <el-select
                  v-model="subUserForm.is_free"
                  placeholder="选择试用状态"
                  filterable
                  allow-create
                  clearable
                >
                  <el-option
                    v-for="item in free_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="商店地区">
                <el-select
                  v-model="subUserForm.shop_country"
                  placeholder="选择商店地区"
                  filterable
                  allow-create
                  clearable
                >
                  <el-option
                    v-for="item in shop_country_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="时间范围">
                <el-date-picker
                  v-model="subUserForm.date"
                  type="daterange"
                  unlink-panels
                  size="default"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :shortcuts="shortcuts"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  style="height: 32px"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-button
                type="primary"
                @click="query_sub_user_table2"
                style="width: 180px"
                >查询</el-button
              >
              <!-- <el-button
                type="primary"
                @click="query_sub_user_table3"
                style="width: 180px"
                >查询2</el-button
              > -->
              <el-button
                type="danger"
                @click="flush_new_user_by_uid"
                style="width: 180px"
                >刷新安装记录</el-button
              >
              <el-button type="primary" @click="exportFile()"
                >导出数据</el-button
              >
            </el-col>
          </el-row>

          <el-tabs
            type="border-card"
            style="width: 100%"
            @tab-change="changeSubUserTabs"
            v-model="sub_user_tab_index"
          >
            <el-tab-pane label="数据表格" name="0">
              <el-row>
                <el-table
                  :data="copy_userSubTableData"
                  fit
                  border
                  v-loading="loading"
                  max-height="800"
                  id="sub_table"
                  :default-sort="{ prop: 'purchaseDate', order: 'descending' }"
                  @sort-change="subSortChange"
                >
                  <el-table-column
                    prop="purchaseDate"
                    label="订阅日期"
                    align="center"
                    sortable
                  />
                  <el-table-column
                    prop="site_id"
                    label="搜索词"
                    align="center"
                    sortable
                    v-if="hidden_values.indexOf('site_id') == -1"
                  />
                  <el-table-column
                    prop="campaign_id"
                    label="广告组"
                    align="center"
                    sortable
                    v-if="hidden_values.indexOf('campaign_id') == -1"
                  />
                  <el-table-column
                    prop="country"
                    label="国家"
                    align="center"
                    sortable
                  />
                  <el-table-column
                    prop="pub_country"
                    label="语言地区"
                    align="center"
                    sortable
                  />
                  <el-table-column
                    prop="shop_country"
                    label="商店地区"
                    align="center"
                    sortable
                  />
                  <el-table-column
                    prop="is_free"
                    label="初始试用"
                    align="center"
                    sortable
                  >
                    <template #default="scope">
                      <span v-if="scope.row.is_free == 1">是</span>
                      <span v-else>否</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="first_productId"
                    label="初始套餐"
                    align="center"
                    sortable
                  >
                    <template #default="scope">
                      <el-tag
                        v-if="
                          scope.row.first_productId == 'xyz.metrovpn.monthly' ||
                          scope.row.first_productId == 'xyz.metrovpn.monthly3'
                        "
                        >月套餐</el-tag
                      >
                      <el-tag
                        type="success"
                        v-if="
                          scope.row.first_productId == 'xyz.metrovpn.quarterly'
                        "
                        >季套餐</el-tag
                      >
                      <el-tag
                        type="danger"
                        v-if="
                          scope.row.first_productId == 'xyz.metrovpn.yearly' ||
                          scope.row.first_productId == 'xyz.metrovpn.yearly3'
                        "
                        >年套餐</el-tag
                      >
                      <el-tag
                        type="warning"
                        v-if="
                          scope.row.first_productId == 'xyz.metrovpn.6months'
                        "
                        >半年套餐</el-tag
                      >
                      <el-tag
                        type="info"
                        v-if="scope.row.first_productId == 'xyz.metrovpn.2-year-pass'"
                        >两年套餐</el-tag
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="productId"
                    label="最新套餐"
                    align="center"
                    sortable
                  >
                    <template #default="scope">
                      <el-tag
                        v-if="
                          scope.row.productId == 'xyz.metrovpn.monthly' ||
                          scope.row.productId == 'xyz.metrovpn.monthly3'
                        "
                        >月套餐</el-tag
                      >
                      <el-tag
                        v-if="scope.row.productId == 'xyz.metrovpn.quarterly'"
                        type="success"
                        >季套餐</el-tag
                      >
                      <el-tag
                        v-if="
                          scope.row.productId == 'xyz.metrovpn.yearly' ||
                          scope.row.productId == 'xyz.metrovpn.yearly3'
                        "
                        type="danger"
                        >年套餐</el-tag
                      >
                      <el-tag
                        v-if="scope.row.productId == 'xyz.metrovpn.6months'"
                        type="warning"
                        >半年套餐</el-tag
                      >
                      <el-tag
                        type="info"
                        v-if="scope.row.productId == 'xyz.metrovpn.2-year-pass'"
                        >两年套餐</el-tag
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="offerType"
                    label="类型"
                    align="center"
                    sortable
                  />
                  <el-table-column
                    prop="register_time"
                    label="注册"
                    align="center"
                    sortable
                  />
                  <el-table-column
                    prop="install_time"
                    label="安装"
                    align="center"
                    sortable
                  />
                  <el-table-column
                    prop="first_sub_time"
                    label="首订"
                    align="center"
                    sortable
                  />
                  <el-table-column
                    prop="sub_diff"
                    label="转换"
                    align="center"
                    sortable
                  >
                    <template #default="scope">
                      {{ scope.row.sub_diff }}天
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="expiresDate"
                    label="过期"
                    align="center"
                    sortable
                  />
                  <el-table-column
                    prop="priceCurrencyCode"
                    label="货币"
                    align="center"
                    sortable
                  />
                  <el-table-column
                    prop="priceAmountMicros"
                    label="费用"
                    align="center"
                  />
                  <el-table-column prop="email" label="邮箱" align="center" />
                  <el-table-column
                    prop="sub_status"
                    label="续费"
                    align="center"
                  />
                  <el-table-column
                    prop="sub_times"
                    label="订阅次数"
                    align="center"
                    sortable
                    v-if="hidden_values.indexOf('sub_times') == -1"
                  />
                  <el-table-column label="详情" align="center">
                    <template #default="scope">
                      <el-button
                        type="primary"
                        @click="open_sub_record2(scope.row.uid)"
                        >查看记录</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <el-row>
                  <span style="margin: 20px">总数量:{{ total_sub_user }}</span>
                  <el-pagination
                    :current-page="currentPage"
                    background
                    layout="prev, pager, next"
                    :total="total_sub_user"
                    :page-size="100"
                    @current-change="handleCurrentChange"
                  />
                </el-row>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="数据图表" name="1">
              <div style="margin-top: 20px">
                <div style="display: flex">
                  <el-row id="sub_status_pie_chart" style="width: 50%">
                  </el-row>
                  <el-row id="sub_country_pie_chart" style="width: 50%">
                  </el-row>
                </div>
                <div style="display: flex">
                  <el-row id="sub_campaign_pie_chart" style="width: 50%">
                  </el-row>
                  <el-row id="sub_site_pie_chart" style="width: 50%"> </el-row>
                </div>
                <div>
                  <h2>搜索来源前10国家分布</h2>
                  <p><span>排除0 和 null 的搜索来源后的排序</span></p>
                  <el-row id="top_10_site_country_chart"> </el-row>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>

        <el-tab-pane v-if="get_table_view('续订表')" label="续订表">
          <h3>续订表</h3>

          <el-form
            :inline="true"
            :model="reNewPlanForm"
            style="width: 100%"
            label-position="left"
          >
            <el-row>
              <el-col :span="6">
                <el-form-item label="时间范围">
                  <el-date-picker
                    v-model="reNewPlanForm.date"
                    type="daterange"
                    unlink-panels
                    size="default"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :shortcuts="renew_shortcuts"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    style="height: 32px"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="6">
                <el-form-item label="选择套餐" style="width:70%">
                  <el-select
                    v-model="reNewPlanForm.plan"
                    placeholder="选择套餐"
                    filterable
                    allow-create
                    clearable
                    @change="change_date_range"
                  >
                    <el-option
                      v-for="(item, index) in plan_list"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="get_renew_table"
                    style="width: 180px"
                    >查询</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <p>续订规则:</p>
          <p>订阅数 = 当天新增的订阅 仅限第一次订阅</p>
          <p>续订数 = 下一周期 相同套餐续费上了的数目, 切换了套餐的不算续订</p>
          <p>
            注意:不一定是固定周期，比如月套餐，6月20日第一次订阅，7月20日续费失败。往后60天内续费成功(Apple最长60天重试扣费)，也算一次成功续费
          </p>
          <el-row>
            <el-table
              :data="renewTableData"
              fit
              border
              height="600"
              v-loading="loading"
              element-loading-text="加载中..."
              :default-sort="{ prop: 'next_date', order: 'descending' }"
            >
              <el-table-column
                prop="date"
                label="订阅日期"
                align="center"
                sortable
              />
              <el-table-column
                prop="sub"
                label="订阅数"
                align="center"
                sortable
              />
              <el-table-column
                prop="renew"
                label="续订数"
                align="center"
                sortable
              />

              <el-table-column
                prop="renew_rate"
                label="续订率"
                align="center"
                sortable
              >
                <template #default="scope">
                  <span :class="scope.row.renew_rate > 0 ? 'danger' : ''"
                    >{{ scope.row.renew_rate }}%</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-tab-pane>
        <el-tab-pane
          v-if="get_table_view('ASA推广成效分析')"
          label="ASA推广成效分析"
        >
          <h3>ASA推广成效分析</h3>
          <div class="remark-text" style="margin-bottom: 5px">
            <el-tooltip class="box-item" effect="dark" placement="top-start">
              <template #content>
                <div>
                  <ol>
                    <li>新增订阅: 当天的新增订阅数据，不含续订的数据</li>
                    <li>unknown: 字段的数值为空或为0的数据</li>
                    <li>订阅单价: ASA支出 / 新增订阅</li>
                    <li>付费单价: ASA支出 / 转正量</li>
                    <li>新增有效率: ( 新增 / ASA安装 ) * 100</li>
                    <li>回报率: ( LTV / ASA支出 ) * 100</li>
                    <li>
                      LTV计算更新:
                      取商店页地区对应第一年所得的货币、收入及实时汇率转换为美元计算所得<br />
                      例如：商店页地区PHL,
                      月套餐对应我们第一年所得的货币收入为296.65 PHP,
                      实时汇率为56.1706, 则订单收入计为296.65 / 56.1706 ≈ 5.28
                      USD
                    </li>
                  </ol>
                </div>
              </template>
              <el-button style="color: red">备注</el-button>
            </el-tooltip>
          </div>

          <el-form
            :inline="true"
            :model="asaForm"
            style="width: 100%"
            label-position="left"
          >
            <el-row>
              <el-col :span="6">
                <el-form-item label="时间范围:">
                  <el-date-picker
                    v-model="asaForm.date"
                    type="daterange"
                    unlink-panels
                    size="default"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :shortcuts="normal_date_shortcuts"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    style="height: 32px"
                  />
                </el-form-item>
              </el-col>

              <el-col :span="4">
              <el-form-item label="项目:">
                <el-select
                  v-model="asaForm.p"
                  placeholder="选择项目"
                  filterable
                  allow-create
                  clearable
                >
                  <el-option
                    v-for="item in project_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

              <el-col :span="6">
                <!-- <el-form-item>
                  <el-button
                    type="primary"
                    @click="get_asa_campaign_data_source"
                    style="width: 150px"
                    >查询</el-button
                  >
                </el-form-item> -->
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="get_asa_campaign_data_source2"
                    style="width: 180px"
                    >查询</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <div style="margin-bottom: 10px">
            <el-button @click="export_campaign_data"
              >导出广告系列数据</el-button
            >
            <el-button @click="openNewPage('/data/asa_table2/first-year-income?token=3282d437bde390612023c5237be0fa6d&p=MetroVPN')">查看MetroVPN各地区第一年所得收入</el-button>
            <el-button @click="openNewPage('/data/asa_table2/first-year-income?token=3282d437bde390612023c5237be0fa6d&p=LineVPN')">查看LineVPN各地区第一年所得收入</el-button>
          </div>
          <el-row :gutter="20">
            <el-col :span="24" v-if="campaign_show">
              <el-table
                :data="asa_campaign_data_source"
                fit
                border
                height="800"
                v-loading="campaign_loading"
                element-loading-text="加载中..."
                id="campaign_table"
                :default-sort="{ prop: 'total', order: 'descending' }"
                :cell-style="asa_cellStyle"
              >
                <el-table-column
                  prop="campaign_id"
                  label="广告系列"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <a
                      @click="get_asa_site_data_source(scope.row.campaign_id)"
                      style="
                        color: #409eff;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >{{ scope.row.campaign_id }}</a
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="campaignName"
                  label="系列名称"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.campaignName }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="new"
                  label="新增设备"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="total"
                  label="新增订阅"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="pay"
                  label="新增付费"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="ltv"
                  label="LTV"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="sub_rate"
                  label="订阅率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span
                      :class="scope.row.sub_rate > 0 ? 'danger' : ''"
                      v-if="is_export_campaign"
                      >{{ scope.row.sub_rate }}</span
                    >
                    <span :class="scope.row.sub_rate > 0 ? 'danger' : ''" v-else
                      >{{ scope.row.sub_rate }}%</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="pay_rate"
                  label="付费率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span
                      :class="scope.row.pay_rate > 0 ? 'danger' : ''"
                      v-if="is_export_campaign"
                      >{{ scope.row.pay_rate }}</span
                    >
                    <span :class="scope.row.pay_rate > 0 ? 'danger' : ''" v-else
                      >{{ scope.row.pay_rate }}%</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="spend"
                  label="ASA支出"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.spend }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="installs"
                  label="ASA安装"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.installs }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="avg_sub"
                  label="订阅单价"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.avg_sub }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="avg_pay"
                  label="付费单价"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.avg_pay }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="install_rate"
                  label="新增有效率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.install_rate }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="roi"
                  label="回报率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.roi }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>

            <el-col :span="24" v-else>
              <el-row>
                <el-col>
                  <h2>{{ asa_site_title }}</h2>
                </el-col>
                <el-col>
                  <el-button @click="back_to_campaign_data"
                    >返回广告系列</el-button
                  >
                  <el-button @click="export_site_data"
                    >导出搜索词数据</el-button
                  >
                </el-col>
              </el-row>
              <el-table
                :data="asa_site_data_source"
                fit
                border
                height="800"
                v-loading="site_loading"
                element-loading-text="加载中..."
                :default-sort="{ prop: 'total', order: 'descending' }"
                id="site_table"
              >
                <el-table-column
                  prop="site_id"
                  label="搜索词ID"
                  align="center"
                  sortable
                />

                <el-table-column
                  prop="campaignName"
                  label="搜索词名称"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.campaignName }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="new"
                  label="新增设备"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="total"
                  label="新增订阅"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="pay"
                  label="新增付费"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="ltv"
                  label="LTV"
                  align="center"
                  sortable
                />

                <el-table-column
                  prop="sub_rate"
                  label="订阅率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span
                      :class="scope.row.sub_rate > 0 ? 'danger' : ''"
                      v-if="is_export_site"
                      >{{ scope.row.sub_rate }}</span
                    >
                    <span :class="scope.row.sub_rate > 0 ? 'danger' : ''" v-else
                      >{{ scope.row.sub_rate }}%</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="pay_rate"
                  label="付费率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span
                      :class="scope.row.pay_rate > 0 ? 'danger' : ''"
                      v-if="is_export_site"
                      >{{ scope.row.pay_rate }}</span
                    >
                    <span :class="scope.row.pay_rate > 0 ? 'danger' : ''" v-else
                      >{{ scope.row.pay_rate }}%</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="spend"
                  label="ASA支出"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.spend }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="installs"
                  label="ASA安装"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.installs }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="avg_sub"
                  label="订阅单价"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.avg_sub }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="avg_pay"
                  label="付费单价"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.avg_pay }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="install_rate"
                  label="新增有效率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.install_rate }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="roi"
                  label="回报率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.roi }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane
          v-if="get_table_view('Android推广成效分析')"
          label="Android推广成效分析"
        >
          <h3>Android推广成效分析</h3>

          <el-form
            :inline="true"
            :model="fbForm"
            style="width: 100%"
            label-position="left"
          >
            <el-row>
              <el-col :span="6.5">
                <el-form-item label="时间范围:">
                  <el-date-picker
                    v-model="fbForm.date"
                    type="daterange"
                    unlink-panels
                    size="default"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :shortcuts="normal_date_shortcuts"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    style="height: 32px"
                  />
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item label="渠道:" style="width:70%">
                  <el-select
                    v-model="fbForm.channel"
                    placeholder="选择渠道"
                    filterable
                    allow-create
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in android_channel_list"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="7">
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="get_fb_campaign_data_source"
                    style="width: 180px"
                    >查询</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="fb_renew_show = !fb_renew_show"
                    style="width: 180px"
                    >展开详情</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <div v-if="!fb_renew_show">
              <p style="color: red; font-size: 14px">
                2024年6月19日的数据才开始正确归因deviceId和广告系列
              </p>
              <p style="color: red; font-size: 14px">
                归因逻辑:
                购买token去找绑定deviceId，deviceId去找广告系列，找不到归为unknown
              </p>
              <p style="color: red; font-size: 14px">
                新增订阅: 新增试用+ 新增直接付费
              </p>
              <p style="color: red; font-size: 14px">
                新增付费: 新增直接付费+ 试用转付费(计入试用日期)
              </p>
              <p style="color: red; font-size: 14px">
                订阅单价: FB支出 / 新增订阅
              </p>
              <p style="color: red; font-size: 14px">
                付费单价: FB支出 / 新增付费
              </p>
              <p style="color: red; font-size: 14px">
                LTV: 时间范围内新增订阅的全生命周期价值
              </p>
              <p style="color: red; font-size: 14px">
                新增有效率: ( 新增 / FB安装 ) * 100
              </p>
              <p style="color: red; font-size: 14px">
                回报率: ( LTV / FB支出 ) * 100
              </p>
            </div>
          </el-form>
          <el-row :gutter="20" v-if="!fb_renew_show">
            <el-col :span="24" v-if="fb_campaign_show">
              <el-row>
                <el-col>
                  <h2>广告系列分析</h2>
                </el-col>
                <el-col>
                  <el-button @click="export_fb_campaign_data"
                    >导出广告系列数据</el-button
                  >
                </el-col>
              </el-row>

              <el-table
                :data="fb_campaign_data_source"
                fit
                border
                v-loading="fb_campaign_loading"
                element-loading-text="加载中..."
                id="fb_campaign_table"
                :default-sort="{ prop: 'total', order: 'descending' }"
                :cell-style="fb_cellStyle"
              >
                <el-table-column
                  prop="campaign_id"
                  label="广告系列"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <a
                      @click="get_fb_site_data_source(scope.row.campaign_id)"
                      style="
                        color: #409eff;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >{{ scope.row.campaign_id }}</a
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="campaignName"
                  label="系列名称"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.campaignName }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="campaignStatus"
                  label="投放状态"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <el-tag
                      v-if="scope.row.campaignStatus"
                      :type="
                        scope.row.campaignStatus === 'ACTIVE'
                          ? 'success'
                          : 'danger'
                      "
                    >
                      {{ scope.row.campaignStatus }}
                    </el-tag>
                    <span v-else>{{ scope.row.campaignStatus }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="new"
                  label="新增设备"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="total"
                  label="新增订阅"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="pay"
                  label="新增付费"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="ltv"
                  label="LTV"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="sub_rate"
                  label="订阅率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span
                      :formatter="formatPercentage"
                      :class="scope.row.sub_rate > 0 ? 'danger' : ''"
                      v-if="is_export_campaign"
                      >{{ scope.row.sub_rate }}</span
                    >
                    <span
                      :formatter="formatPercentage"
                      :class="scope.row.sub_rate > 0 ? 'danger' : ''"
                      v-else
                      >{{ scope.row.sub_rate }}%</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="pay_rate"
                  label="付费率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span
                      :formatter="formatPercentage"
                      :class="scope.row.pay_rate > 0 ? 'danger' : ''"
                      v-if="is_export_campaign"
                      >{{ scope.row.pay_rate }}</span
                    >
                    <span
                      :formatter="formatPercentage"
                      :class="scope.row.pay_rate > 0 ? 'danger' : ''"
                      v-else
                      >{{ scope.row.pay_rate }}%</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="spend"
                  label="FB支出"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.spend }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="installs"
                  label="FB安装"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.installs }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="avg_sub"
                  label="订阅单价"
                  align="center"
                  :formatter="formatNormal"
                  sortable
                >
                </el-table-column>

                <el-table-column
                  prop="avg_pay"
                  label="付费单价"
                  align="center"
                  :formatter="formatNormal"
                  sortable
                >
                </el-table-column>

                <el-table-column
                  prop="install_rate"
                  label="新增有效率"
                  align="center"
                  :formatter="formatPercentage"
                  sortable
                >
                </el-table-column>

                <el-table-column
                  prop="roi"
                  label="回报率"
                  align="center"
                  :formatter="formatPercentage"
                  sortable
                >
                </el-table-column>
              </el-table>
            </el-col>

            <el-col :span="24" v-else-if="fb_adset_show">
              <el-row>
                <el-col>
                  <h2>{{ fb_site_title }}</h2>
                </el-col>
                <el-col>
                  <el-button @click="back_to_fb_campaign_data"
                    >返回广告系列</el-button
                  >
                  <el-button @click="export_fb_site_data"
                    >导出广告单元数据</el-button
                  >
                </el-col>
              </el-row>
              <el-table
                :data="fb_site_data_source"
                fit
                border
                v-loading="fb_site_loading"
                element-loading-text="加载中..."
                :default-sort="{ prop: 'total', order: 'descending' }"
                id="fb_site_table"
                :cell-style="fb_adset_cellStyle"
              >
                <el-table-column
                  prop="site_id"
                  label="广告单元id"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <a
                      @click="get_fb_ad_data_source(scope.row.site_id)"
                      style="
                        color: #409eff;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >{{ scope.row.site_id }}</a
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="campaignName"
                  label="广告单元名称"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.campaignName }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="new"
                  label="新增设备"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="total"
                  label="新增订阅"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="pay"
                  label="新增付费"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="ltv"
                  label="LTV"
                  align="center"
                  sortable
                />

                <el-table-column
                  prop="sub_rate"
                  label="订阅率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span
                      :formatter="formatPercentage"
                      :class="scope.row.sub_rate > 0 ? 'danger' : ''"
                      v-if="is_export_site"
                      >{{ scope.row.sub_rate }}</span
                    >
                    <span
                      :formatter="formatPercentage"
                      :class="scope.row.sub_rate > 0 ? 'danger' : ''"
                      v-else
                      >{{ scope.row.sub_rate }}%</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="pay_rate"
                  label="付费率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span
                      :formatter="formatPercentage"
                      :class="scope.row.pay_rate > 0 ? 'danger' : ''"
                      v-if="is_export_site"
                      >{{ scope.row.pay_rate }}</span
                    >
                    <span
                      :formatter="formatPercentage"
                      :class="scope.row.pay_rate > 0 ? 'danger' : ''"
                      v-else
                      >{{ scope.row.pay_rate }}%</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="spend"
                  label="FB支出"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.spend }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="installs"
                  label="FB安装"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.installs }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="avg_sub"
                  label="订阅单价"
                  align="center"
                  :formatter="formatNormal"
                  sortable
                >
                </el-table-column>

                <el-table-column
                  prop="avg_pay"
                  label="付费单价"
                  align="center"
                  :formatter="formatNormal"
                  sortable
                >
                </el-table-column>

                <el-table-column
                  prop="install_rate"
                  label="新增有效率"
                  align="center"
                  :formatter="formatPercentage"
                  sortable
                >
                </el-table-column>

                <el-table-column
                  prop="roi"
                  label="回报率"
                  align="center"
                  :formatter="formatPercentage"
                  sortable
                >
                </el-table-column>
              </el-table>
            </el-col>

            <el-col :span="24" v-else-if="fb_ad_show">
              <el-row>
                <el-col>
                  <h2>{{ fb_ad_title }}</h2>
                </el-col>
                <el-col>
                  <el-button @click="back_to_fb_adset_data"
                    >返回广告单元</el-button
                  >
                  <el-button @click="export_fb_ad_data"
                    >导出广告id数据</el-button
                  >
                </el-col>
              </el-row>
              <el-table
                :data="fb_ad_data_source"
                fit
                border
                v-loading="fb_ad_loading"
                element-loading-text="加载中..."
                :default-sort="{ prop: 'total', order: 'descending' }"
                id="fb_ad_table"
              >
                <el-table-column
                  prop="ad_id"
                  label="广告id"
                  align="center"
                  sortable
                >
                </el-table-column>

                <el-table-column
                  prop="campaignName"
                  label="广告名称"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.campaignName }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="new"
                  label="新增设备"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="total"
                  label="新增订阅"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="pay"
                  label="新增付费"
                  align="center"
                  sortable
                />
                <el-table-column
                  prop="ltv"
                  label="LTV"
                  align="center"
                  sortable
                />

                <el-table-column
                  prop="sub_rate"
                  label="订阅率"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span
                      :formatter="formatPercentage"
                      :class="scope.row.sub_rate > 0 ? 'danger' : ''"
                      v-if="is_export_site"
                      >{{ scope.row.sub_rate }}</span
                    >
                    <span
                      :formatter="formatPercentage"
                      :class="scope.row.sub_rate > 0 ? 'danger' : ''"
                      v-else
                      >{{ scope.row.sub_rate }}%</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="pay_rate"
                  label="付费率"
                  align="center"
                  :formatter="formatPercentage"
                  sortable
                >
                  <template #default="scope">
                    <span
                      :class="scope.row.pay_rate > 0 ? 'danger' : ''"
                      v-if="is_export_site"
                      >{{ scope.row.pay_rate }}</span
                    >
                    <span :class="scope.row.pay_rate > 0 ? 'danger' : ''" v-else
                      >{{ scope.row.pay_rate }}%</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="spend"
                  label="FB支出"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.spend }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="installs"
                  label="FB安装"
                  align="center"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.installs }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="avg_sub"
                  label="订阅单价"
                  align="center"
                  :formatter="formatNormal"
                  sortable
                >
                </el-table-column>

                <el-table-column
                  prop="avg_pay"
                  label="付费单价"
                  align="center"
                  :formatter="formatNormal"
                  sortable
                >
                </el-table-column>

                <el-table-column
                  prop="install_rate"
                  label="新增有效率"
                  align="center"
                  :formatter="formatPercentage"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.install_rate }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="roi"
                  label="回报率"
                  align="center"
                  :formatter="formatPercentage"
                  sortable
                >
                  <template #default="scope">
                    <span>{{ scope.row.roi }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <div style="overflow: auto; width: 100%">
              <FbSubTable
                @get_fb_site_data_source="get_fb_site_data_source"
                @get_fb_ad_data_source="get_fb_ad_data_source"
                @back_to_fb_campaign_data="back_to_fb_campaign_data"
                @back_to_fb_adset_data="back_to_fb_adset_data"
                :get_fb_type="get_fb_type"
                :fb_renew_show="fb_renew_show"
                :fb_campaign_plan_data="fb_campaign_plan_data"
                :fb_site_plan_data="fb_site_plan_data"
                :fb_ad_plan_data="fb_ad_plan_data"
                :fb_campaign_show="fb_campaign_show"
                :fb_adset_show="fb_adset_show"
                :fb_ad_show="fb_ad_show"
                :current_fb_campaign_id="current_fb_campaign_id"
                :current_fb_site_id="current_fb_site_id"
                :fb_campaign_plan_loading="fb_campaign_plan_loading"
              >
              </FbSubTable>
            </div>
          </el-row>
        </el-tab-pane>

        <el-tab-pane v-if="get_table_view('收入分析表')" label="收入分析表">
          <h3>全订阅平台收入分析表</h3>

          <el-form
            :inline="true"
            :model="income_form"
            style="width: 100%"
            label-position="left"
          >
            <el-row>
              <el-col :span="6">
                <div style="display: flex">
                  <div>
                    <el-form-item label="时间范围:">
                      <el-date-picker
                        v-model="income_form.date"
                        type="daterange"
                        unlink-panels
                        size="default"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :shortcuts="normal_date_shortcuts"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        style="height: 32px"
                      />
                    </el-form-item>
                  </div>

                  <div>
                    <el-form-item label="订阅平台:">
                      <el-select
                        v-model="sub_platform_name"
                        filterable
                        placeholder="选择订阅平台"
                        clearable
                        style="width: 200px"
                      >
                        <el-option
                          v-for="item in sub_platform_list"
                          :key="item.tag"
                          :label="item.tag"
                          :value="item.tag"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>


              <el-form-item label="项目:">
                <el-select
                  v-model="project"
                  placeholder="选择项目"
                  filterable
                  allow-create
                  clearable
                  style="width: 200px"
                >
                  <el-option
                    v-for="item in project_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="get_income_table2"
                    style="width: 180px"
                    >查询</el-button
                  >
                </el-form-item>
                <!-- <el-form-item>
                  <el-button
                    type="primary"
                    @click="get_income_table3"
                    style="width: 180px"
                    >查询2</el-button
                  >
                </el-form-item> -->
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="renew_show = !renew_show"
                    style="width: 180px"
                    >展开详情</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-row :gutter="20">
            <div
              style="max-height: 850px; min-height: 130px; overflow: auto; width: 100%"
              v-loading="query_income_loading2"
              element-loading-text="加载中..."
            >
              <IncomeTable
                :table_data="income_data_table2"
                :renew_show="renew_show"
              ></IncomeTable>
            </div>
          </el-row>
        </el-tab-pane>

        <el-tab-pane v-if="get_table_view('用户详情')" label="用户详情">
          <div>
            <UserInfo></UserInfo>
          </div>
        </el-tab-pane>

        <el-tab-pane v-if="get_table_view('心愿单图表')" label="心愿单图表">
          <div style="width: 100%">
            <Wish></Wish>
          </div>
        </el-tab-pane>

        <el-tab-pane v-if="get_table_view('奖励时长后台')" label="奖励时长后台">
          <div style="width: 100%">
            <GiftTime></GiftTime>
          </div>
        </el-tab-pane>

        <el-tab-pane v-if="get_table_view('赢回邮件后台')" label="赢回邮件后台">
          <div style="width: 100%">
            <WinBackEmail></WinBackEmail>
          </div>
        </el-tab-pane>

        <el-tab-pane v-if="get_table_view('邮件发送统计看板')" label="邮件发送统计看板">
          <div style="width: 100%">
            <EmailSendStatics></EmailSendStatics>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>
  
  
<script>
import {
  get_new_device_table,
  get_sub_table,
  get_sub_user_table,
  get_sub_user_table2,
  get_sub_user_table3,
  get_filter_options,
  flush_new_user_info_by_uid,
  get_renew_data_table,
  fetch_sub_record_tables,
  fetch_sub_record_tables2,
  get_asa_campaign_tables,
  get_asa_campaign_tables2,
  get_fb_campaign_tables,
  get_income_tables,
  get_income_tables2,
  get_income_tables3,
  get_token,
  baseUrl,
} from "../services/requests.js";
import IncomeTable from "./income_table.vue";
import FbSubTable from "./fb_sub_table2.vue";
import UserInfo from "./UserInfo.vue";
import Wish from "./Wish.vue";
import GiftTime from "./gift_time.vue";
import WinBackEmail from "./WinBackEmail.vue";
import EmailSendStatics from "./EmailSend.vue";
import { ElMessage } from "element-plus";
import * as echarts from "echarts";
import FileSaver from "file-saver";
import XLSX from "xlsx";

// 获取当前日期  和 7天前的日期
const getDate = () => {
  const end = new Date();
  const start = new Date();
  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
  const start_date = start.toISOString().split("T")[0];
  const end_date = end.toISOString().split("T")[0];
  return [start_date, end_date];
};
const date_range = getDate();

// 获取上个周期的续订日期
const get_renew_date = () => {
  const end = new Date();
  const start = new Date();
  end.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 2);
  const start_date = start.toISOString().split("T")[0];
  const end_date = end.toISOString().split("T")[0];
  return [start_date, end_date];
};
const renew_date_range = get_renew_date();

export default {
  components: {
    IncomeTable,
    FbSubTable,
    UserInfo,
    Wish,
    GiftTime,
    WinBackEmail,
    EmailSendStatics
  },
  data() {
    return {
      query_income_loading: false,
      query_income_loading2: false,
      campaign_show: true,
      fb_campaign_show: true,
      fb_adset_show: false,
      fb_ad_show: false,
      sub_platform_name: "App Store",
      project: "MetroVPN",
      sub_platform_list: [
        { tag: "All", value: "All" },
        { tag: "App Store", value: "App Store" },
        { tag: "Google Play", value: "Google Play" },
        { tag: "官网", value: "官网" },
        { tag: "PayPal", value: "PayPal" },
        { tag: "Stripe", value: "Stripe" },
        { tag: "Beamo", value: "Beamo" },
        { tag: "Payssion", value: "Payssion" },
      ],
      sub_platform_map: {
        All: "All",
        "App Store": "appstore",
        "Google Play": "googleplay",
        官网: "website",
        PayPal: "paypal",
        Stripe: "stripe",
        Beamo: "Beamo",
        Payssion: "payssion",
      },
      renew_show: false,
      fb_renew_show: false,
      show_details: true,
      fb_plan_data: [],
      is_export_campaign: false,
      is_export_site: false,
      asa_site_title: "搜索词分析",
      fb_site_title: "广告单元分析",
      fb_ad_title: "广告分析",
      asa_campaign_data_source: [],
      asa_site_data_source: [],
      campaign_loading: false,
      fb_campaign_data_source: [],
      fb_campaign_plan_data: [],
      fb_campaign_loading: false,
      fb_campaign_plan_loading: false,
      site_loading: false,
      fb_site_data_source: [],
      fb_site_plan_data: [],
      fb_site_loading: false,
      get_fb_type: 1,
      fb_ad_data_source: [],
      fb_ad_plan_data: [],
      fb_ad_loading: false,
      normal_date_shortcuts: [
        {
          text: "今天",
          value: () => {
            const end = new Date();
            const start = new Date();
            return [start, end];
          },
        },
        {
          text: "昨天",
          value: () => {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            return [start, end];
          },
        },
        {
          text: "最近一周",
          value: () => {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "最近一个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "最近三个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 3);
            return [start, end];
          },
        },
      ],
      renew_shortcuts: [
        {
          text: "最近试用周期",
          value: () => {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7 * 2);
            return [start, end];
          },
        },
        {
          text: "最近月周期",
          value: () => {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 2);
            return [start, end];
          },
        },
        {
          text: "最近季周期",
          value: () => {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90 * 2);
            return [start, end];
          },
        },
        {
          text: "最近年周期",
          value: () => {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 2);
            return [start, end];
          },
        },
      ],
      shortcuts: [
        {
          text: "过去一周",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "过去一个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "过去三个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
      platform_list: [],
      new_device_tab_index: "0",
      sub_tab_index: "0",
      sub_user_tab_index: "0",
      country_list: [],
      renewTableData: [],
      userSubTableData: [],
      total_sub_user: 0,
      sub_record_visible: false,
      sub_record_table: [],
      currentPage: 1,
      copy_userSubTableData: [],
      hidden_values: [],
      hidden_sub_values: ["month1", "month12"],
      hidden_sub_options: [
        {
          value: "month1",
          label: "月套餐",
        },
        {
          value: "month3",
          label: "季套餐",
        },
        {
          value: "month6",
          label: "半年套餐",
        },
        {
          value: "month12",
          label: "年套餐",
        },
      ],
      hidden_value_options: [
        {
          value: "site_id",
          label: "搜索词",
        },
        {
          value: "campaign_id",
          label: "广告组",
        },
        {
          value: "sub_times",
          label: "订阅次数",
        },
      ],
      sub_detail_data: {
        total: 0,
        today: 0,
        total_month: 0,
        total_season: 0,
        total_6month: 0,
        total_year: 0,
      },
      subTableData: [],
      deviceTableData: [],
      loading: false,
      site_id_list: [],
      campaign_id_list: [],
      income_data_table: [],
      income_data_table2: [],
      plan_list: [
        {
          value: "month",
          label: "标准月套餐",
        },
        {
          value: "month_free",
          label: "推介月套餐",
        },
        {
          value: "month_free_zero",
          label: "试用月套餐",
        },
        {
          value: "year",
          label: "年套餐",
        },
        {
          value: "year_free",
          label: "推介年套餐",
        },
        {
          value: "year_free_zero",
          label: "试用年套餐",
        },
        {
          value: "quarter",
          label: "季套餐",
        },
        {
          value: "quarter_free",
          label: "推介季套餐",
        },
        {
          value: "quarter_free_zero",
          label: "试用季套餐",
        },
      ],
      android_channel_list: [
        {
          value: "All",
          label: "All",
        },
        {
          value: "Facebook",
          label: "Facebook",
        },
        {
          value: "kwaiforbusiness - Android",
          label: "kwaiforbusiness - Android",
        },
        {
          value: "unknown",
          label: "自然新增",
        },
      ],
      cycle_list: [],
      new_users: [],
      chart_series: [],
      top_10_site_ids: [],
      loading_record: false,
      priceCurrencyCode_options: [],
      country_options: [],
      pay_source_options: [],
      current_campaign_id: "",
      current_fb_campaign_id: "",
      current_fb_site_id: "",
      subUserForm: {
        start: "",
        end: "",
        date: [],
        site_id: "",
        campaign_id: "",
        uid: "",
        productId: "",
        sub_status: "",
        is_firstSub: "0",
        lang: "",
        country: "",
        priceCurrencyCode: "",
        shop_country: "",
        is_free: "",
        pay_source: "",
      },
      subPlanForm: {
        site_id: "",
        campaign_id: "",
        mcc: "",
        platform: "ios",
        date: date_range,
        start_date: date_range[0],
        end_date: date_range[1],
        plan: "free",
      },
      income_form: {
        date: date_range,
        start_date: date_range[0],
        end_date: date_range[1],
      },
      p_query: "",
      asaForm: {
        date: date_range,
        start_date: date_range[0],
        end_date: date_range[1],
        target_campaign_id: "",
        p: "MetroVPN"
      },
      fbForm: {
        date: date_range,
        start_date: date_range[0],
        end_date: date_range[1],
        target_campaign_id: "",
        target_adset_id: "",
        channel: "Facebook",
      },
      ltvForm: {
        date: date_range,
        start_date: date_range[0],
        end_date: date_range[1],
        target_campaign_id: "",
      },
      reNewPlanForm: {
        site_id: "",
        campaign_id: "",
        mcc: "",
        platform: "ios",
        date: renew_date_range,
        start_date: renew_date_range[0],
        end_date: renew_date_range[1],
        plan: "month_free",
      },
      newDeviceForm: {
        site_id: "",
        campaign_id: "",
        mcc: "",
        platform: "ios",
        date: date_range,
        start_date: date_range[0],
        end_date: date_range[1],
      },
      free_options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "试用",
        },
        {
          value: 0,
          label: "付费",
        },
      ],
      sub_plan_options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "xyz.metrovpn.monthly",
          label: "月套餐",
        },
        {
          value: "xyz.metrovpn.quarterly",
          label: "季套餐",
        },
        {
          value: "xyz.metrovpn.6months",
          label: "半年套餐",
        },
        {
          value: "xyz.metrovpn.yearly",
          label: "年套餐",
        },
      ],
      project_options: [
        {
          value: "MetroVPN",
          label: "MetroVPN",
        },
        {
          value: "LineVPN",
          label: "LineVPN",
        }
      ],
      firstSub_options: [
        {
          value: "0",
          label: "最新订阅日期",
        },
        {
          value: "1",
          label: "首订日期",
        },
        {
          value: "2",
          label: "最新订单生成日期",
        },
      ],
      lang_options: [],
      shop_country_options: [],
      sub_status_options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "自动",
          label: "自动",
        },
        {
          value: "关闭",
          label: "关闭",
        },
        {
          value: "宽限",
          label: "宽限",
        },
        {
          value: "重试",
          label: "重试",
        },
        {
          value: "过期",
          label: "过期",
        },
        {
          value: "升级",
          label: "升级",
        },
        {
          value: "退款",
          label: "退款",
        },
      ],
    };
  },
  computed: {},
  methods: {
    openNewPage(url) {
      var url_blank = ""
      if (baseUrl.indexOf("http:") != -1) {
        var baseUrlLocal = "http://localhost:6500"
        url_blank = `${baseUrlLocal}${url}`
      }
      else {
        url_blank = `${baseUrl}${url}`
      }
      window.open(url_blank, "_blank");
    },
    get_table_view(table_name) {
      const token = get_token();
      // console.log(token)
      // console.log(table_name)
      if (token == "3282d437bde390612023c5237be0fa6d") {
        return true;
      } else if (token == "ab2c0edcfa41e64b45e948c7b2eebbef") {
        if (table_name == "Android推广成效分析") {
          return true;
        }
      }
      return false;
    },
    formatNormal(row, column) {
      if (row[column.property] == null) {
        return "NA";
      } else {
        return `${row[column.property]}`;
      }
    },
    formatPercentage(row, column) {
      if (row[column.property] != 0) {
        if (row[column.property] == null) {
          return "NA";
        } else {
          return `${row[column.property].toFixed(2)}%`;
        }
      }
      return `${row[column.property]}`;
    },
    export_site_data() {
      // 导出搜索词数据
      this.site_loading = true;
      this.is_export_site = true;
      setTimeout(() => {
        let table = document.getElementById("site_table");
        let table_book = XLSX.utils.table_to_book(table);
        var table_write = XLSX.write(table_book, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
        try {
          FileSaver.saveAs(
            new Blob([table_write], {
              type: "application/octet-stream",
            }),
            this.current_campaign_id + "_site_table.xlsx"
          );
          ElMessage({
            showClose: true,
            message: "导出成功",
            center: true,
            type: "success",
          });
        } catch (e) {
          console.log("导出失败", e);
          ElMessage({
            showClose: true,
            message: "导出失败",
            center: true,
            type: "error",
          });
        } finally {
          this.is_export_site = false;
          this.site_loading = false;
        }
      }, 1000);
    },
    export_fb_site_data() {
      // 导出搜索词数据
      this.site_loading = true;
      this.is_export_site = true;
      setTimeout(() => {
        let table = document.getElementById("fb_site_table");
        let table_book = XLSX.utils.table_to_book(table);
        var table_write = XLSX.write(table_book, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
        try {
          FileSaver.saveAs(
            new Blob([table_write], {
              type: "application/octet-stream",
            }),
            this.current_fb_campaign_id + "_fb_set_table.xlsx"
          );
          ElMessage({
            showClose: true,
            message: "导出成功",
            center: true,
            type: "success",
          });
        } catch (e) {
          console.log("导出失败", e);
          ElMessage({
            showClose: true,
            message: "导出失败",
            center: true,
            type: "error",
          });
        } finally {
          this.is_export_site = false;
          this.site_loading = false;
        }
      }, 1000);
    },
    export_fb_ad_data() {
      // 导出搜索词数据
      this.fb_ad_loading = true;
      this.is_export_site = true;
      setTimeout(() => {
        let table = document.getElementById("fb_ad_table");
        let table_book = XLSX.utils.table_to_book(table);
        var table_write = XLSX.write(table_book, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
        try {
          FileSaver.saveAs(
            new Blob([table_write], {
              type: "application/octet-stream",
            }),
            this.current_fb_site_id + "_fb_ad_table.xlsx"
          );
          ElMessage({
            showClose: true,
            message: "导出成功",
            center: true,
            type: "success",
          });
        } catch (e) {
          console.log("导出失败", e);
          ElMessage({
            showClose: true,
            message: "导出失败",
            center: true,
            type: "error",
          });
        } finally {
          this.is_export_site = false;
          this.fb_ad_loading = false;
        }
      }, 1000);
    },
    export_campaign_data() {
      // 导出广告系列的数据
      this.campaign_loading = true;
      this.is_export_campaign = true;
      setTimeout(() => {
        let table = document.getElementById("campaign_table");
        let table_book = XLSX.utils.table_to_book(table);
        var table_write = XLSX.write(table_book, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
        try {
          FileSaver.saveAs(
            new Blob([table_write], {
              type: "application/octet-stream",
            }),
            "campaign_table.xlsx"
          );
          ElMessage({
            showClose: true,
            message: "导出成功",
            center: true,
            type: "success",
          });
        } catch (e) {
          console.log("导出失败", e);
          ElMessage({
            showClose: true,
            message: "导出失败",
            center: true,
            type: "error",
          });
        } finally {
          this.is_export_campaign = false;
          this.campaign_loading = false;
        }
      }, 1000);
    },
    export_fb_campaign_data() {
      // 导出广告系列的数据
      this.fb_campaign_loading = true;
      this.is_export_campaign = true;
      setTimeout(() => {
        let table = document.getElementById("fb_campaign_table");
        let table_book = XLSX.utils.table_to_book(table);
        var table_write = XLSX.write(table_book, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
        try {
          FileSaver.saveAs(
            new Blob([table_write], {
              type: "application/octet-stream",
            }),
            "fb_campaign_table.xlsx"
          );
          ElMessage({
            showClose: true,
            message: "导出成功",
            center: true,
            type: "success",
          });
        } catch (e) {
          console.log("导出失败", e);
          ElMessage({
            showClose: true,
            message: "导出失败",
            center: true,
            type: "error",
          });
        } finally {
          this.is_export_campaign = false;
          this.fb_campaign_loading = false;
        }
      }, 1000);
    },
    get_asa_campaign_data_source() {
      if (this.asaForm.date !== null) {
        this.asaForm.start_date = this.asaForm.date[0];
        this.asaForm.end_date = this.asaForm.date[1];
      } else {
        this.asaForm.start_date = "";
        this.asaForm.end_date = "";
      }
      this.load_asa_data();
    },
    get_asa_campaign_data_source2() {
      if (this.asaForm.p != this.p_query) {
        this.campaign_show = true;
        this.asaForm.target_campaign_id = ""
      }
      this.p_query = this.asaForm.p;
      if (this.asaForm.date !== null) {
        this.asaForm.start_date = this.asaForm.date[0];
        this.asaForm.end_date = this.asaForm.date[1];
      } else {
        this.asaForm.start_date = "";
        this.asaForm.end_date = "";
      }
      this.load_asa_data2();
    },
    get_asa_site_data_source(campaign_id) {
      this.asaForm.p = this.p_query
      if (campaign_id == "合计" || campaign_id == "unknown" || campaign_id == "1017824787" || campaign_id == "1732319029") {
        this.$message.error("不能查看合计、未归因、Basic的关键词数据");
        return;
      }
      this.campaign_show = false;
      this.asa_site_title = `${campaign_id}的搜索词分析`;
      this.current_campaign_id = campaign_id;
      if (this.asaForm.date !== null) {
        this.asaForm.start_date = this.asaForm.date[0];
        this.asaForm.end_date = this.asaForm.date[1];
      } else {
        this.asaForm.start_date = "";
        this.asaForm.end_date = "";
      }
      this.asaForm.target_campaign_id = campaign_id;
      this.load_asa_data2();
    },
    get_fb_campaign_data_source() {
      this.fbForm.target_campaign_id = "";
      this.fbForm.target_adset_id = "";
      if (this.fbForm.date !== null) {
        this.fbForm.start_date = this.fbForm.date[0];
        this.fbForm.end_date = this.fbForm.date[1];
      } else {
        this.fbForm.start_date = "";
        this.fbForm.end_date = "";
      }
      this.load_fb_data(1);
    },
    get_fb_site_data_source(campaign_id) {
      if (campaign_id.length != 18) {
        this.$message.error("不能查看合计和未归因的广告数据");
        return;
      }
      this.fb_campaign_show = false;
      this.fb_site_title = `${campaign_id}的广告单元分析`;
      this.current_fb_campaign_id = campaign_id;
      if (this.fbForm.date !== null) {
        this.fbForm.start_date = this.fbForm.date[0];
        this.fbForm.end_date = this.fbForm.date[1];
      } else {
        this.fbForm.start_date = "";
        this.fbForm.end_date = "";
      }
      this.fbForm.target_campaign_id = campaign_id;
      this.load_fb_data(2);
    },
    get_fb_ad_data_source(site_id) {
      if (site_id.length != 18) {
        this.$message.error("不能查看合计和未归因的广告数据");
        return;
      }
      this.fb_adset_show = false;
      this.fb_ad_title = `${site_id}的广告分析`;
      this.current_fb_site_id = site_id;
      if (this.fbForm.date !== null) {
        this.fbForm.start_date = this.fbForm.date[0];
        this.fbForm.end_date = this.fbForm.date[1];
      } else {
        this.fbForm.start_date = "";
        this.fbForm.end_date = "";
      }
      this.fbForm.target_adset_id = site_id;
      this.load_fb_data(3);
    },

    back_to_campaign_data() {
      this.campaign_show = true;
      this.asaForm.target_campaign_id = "";
    },
    back_to_fb_campaign_data() {
      this.fb_campaign_show = true;
      this.fb_adset_show = false;
      this.fb_ad_show = false;
      this.fbForm.target_campaign_id = "";
      this.get_fb_type = 1;
    },
    back_to_fb_adset_data() {
      this.fb_adset_show = true;
      this.fb_campaign_show = false;
      this.fb_ad_show = false;
      this.fbForm.target_adset_id = "";
      this.get_fb_type = 2;
    },
    load_asa_data() {
      if (this.campaign_show == false) {
        this.site_loading = true;
      } else {
        this.campaign_loading = true;
      }
      get_asa_campaign_tables(this.asaForm).then((res) => {
        if (this.campaign_show == false) {
          this.asa_site_data_source = res.data.data;
          this.site_loading = false;
        } else {
          this.asa_campaign_data_source = res.data.data;
          this.campaign_loading = false;
        }

        ElMessage({
          showClose: true,
          message: "获取搜索词推广分析数据成功",
          center: true,
          type: "success",
        });
      });
    },
    load_asa_data2() {
      if (this.campaign_show == false) {
        this.site_loading = true;
      } else {
        this.campaign_loading = true;
      }
      get_asa_campaign_tables2(this.asaForm).then((res) => {
        if (this.campaign_show == false) {
          this.asa_site_data_source = res.data.data;
          this.site_loading = false;
        } else {
          this.asa_campaign_data_source = res.data.data;
          this.campaign_loading = false;
        }

        ElMessage({
          showClose: true,
          message: "获取搜索词推广分析数据成功",
          center: true,
          type: "success",
        });
      });
    },
    load_fb_data(t) {
      this.get_fb_type = t;
      this.fb_campaign_plan_loading = true;
      if (t == 1) {
        this.fb_campaign_loading = true;
        this.fb_adset_show = false;
        this.fb_ad_show = false;
        this.fb_campaign_show = true;
      } else if (t == 2) {
        this.fb_site_loading = true;
        this.fb_campaign_loading = false;
        this.fb_ad_show = false;
        this.fb_adset_show = true;
      } else {
        this.fb_ad_loading = true;
        this.fb_campaign_loading = false;
        this.fb_adset_show = false;
        this.fb_ad_show = true;
      }
      get_fb_campaign_tables(this.fbForm).then((res) => {
        this.fb_campaign_plan_loading = false;
        if (this.get_fb_type == 1) {
          this.fb_campaign_data_source = res.data.data;
          this.fb_campaign_plan_data = res.data.plan_data;
          this.fb_campaign_loading = false;
        } else if (this.get_fb_type == 2) {
          this.fb_site_data_source = res.data.data;
          this.fb_site_plan_data = res.data.plan_data;
          this.fb_site_loading = false;
        } else {
          this.fb_ad_data_source = res.data.data;
          this.fb_ad_plan_data = res.data.plan_data;
          this.fb_ad_loading = false;
        }
        ElMessage({
          showClose: true,
          message: "获取推广分析数据成功",
          center: true,
          type: "success",
        });
      });
    },
    open_sub_record(uid) {
      this.sub_record_table = [];
      this.sub_record_visible = true;
      this.loading_record = true;
      console.log("订阅uid", uid);
      fetch_sub_record_tables({
        uid: uid,
      }).then((res) => {
        this.loading_record = false;
        if (res.data.code == 200) {
          this.sub_record_table = res.data.data;
          ElMessage({
            showClose: true,
            message: "获取订阅记录成功",
            center: true,
            type: "success",
          });
        } else {
          ElMessage({
            showClose: true,
            message: "获取订阅记录失败",
            center: true,
            type: "error",
          });
        }
      });
    },

    open_sub_record2(uid) {
      this.sub_record_table = [];
      this.sub_record_visible = true;
      this.loading_record = true;
      console.log("订阅uid", uid);
      fetch_sub_record_tables2({
        uid: uid,
        platform: this.sub_platform_map[this.sub_platform_name],
      }).then((res) => {
        this.loading_record = false;
        if (res.data.code == 200) {
          this.sub_record_table = res.data.data;
          ElMessage({
            showClose: true,
            message: "获取订阅记录成功",
            center: true,
            type: "success",
          });
        } else {
          ElMessage({
            showClose: true,
            message: "获取订阅记录失败",
            center: true,
            type: "error",
          });
        }
      });
    },
    asa_cellStyle({ row }) {
      if (row.campaign_id == this.current_campaign_id) {
        return {
          background: "#d0ebff",
        };
      }
      return "";
    },
    fb_cellStyle({ row }) {
      if (row.campaign_id == this.current_fb_campaign_id) {
        return {
          background: "#d0ebff",
        };
      }
      return "";
    },
    fb_adset_cellStyle({ row }) {
      if (row.site_id == this.current_fb_site_id) {
        return {
          background: "#d0ebff",
        };
      }
      return "";
    },
    cellStyle({ columnIndex }) {
      console.log(columnIndex);
      if (15 <= columnIndex && columnIndex <= 20) {
        // 月

        return {
          background: "#c3fae8",
        };
      }
      if (21 <= columnIndex && columnIndex <= 26) {
        // 年

        return {
          background: "#d0ebff",
        };
      }
      if (27 <= columnIndex && columnIndex <= 32) {
        // 季

        return {
          background: "#ffec99",
        };
      }
      if (33 <= columnIndex && columnIndex <= 38) {
        // 半年

        return {
          background: "#ffe3e3",
        };
      }
      return "";
    },
    changeValue(value) {
      console.log("改变", value);
    },
    subSortChange(column, prop, order) {
      prop = column.prop;
      order = column.order;
      console.log(prop, order);

      if (order != null) {
        // 重新置换为1
        this.currentPage = 1;
        // 根据pprop排序this.copy_userSubTableData
        this.fi;
        var tempSortData = [...this.userSubTableData];

        tempSortData.sort((a, b) => {
          if (order === "ascending") {
            if (
              [
                "register_time",
                "install_time",
                "first_sub_time",
                "purchaseDate",
                "expiresDate",
              ].includes(prop)
            ) {
              return a[prop].localeCompare(b[prop]);
            } else {
              return a[prop] - b[prop];
            }
          } else {
            if (
              [
                "register_time",
                "install_time",
                "first_sub_time",
                "purchaseDate",
                "expiresDate",
              ].includes(prop)
            ) {
              return b[prop].localeCompare(a[prop]);
            } else {
              return b[prop] - a[prop];
            }
          }
        });
        // 得到一个排序完的数据
        this.userSubTableData = [...tempSortData];
        // 过滤数据
        this.filter_sub_user_table(1);
      }
    },
    change_date_range(value) {
      if (value == "month" || value == "month_free") {
        const end = new Date();
        const start = new Date();
        end.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 2);
        const start_date = start.toISOString().split("T")[0];
        const end_date = end.toISOString().split("T")[0];
        this.reNewPlanForm.date = [start_date, end_date];
      }
      if (value == "year" || value == "year_free") {
        const end = new Date();
        const start = new Date();
        end.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7 * 2);
        const start_date = start.toISOString().split("T")[0];
        const end_date = end.toISOString().split("T")[0];
        this.reNewPlanForm.date = [start_date, end_date];
      }
      if (value == "quarter" || value == "quarter_free") {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        const start_date = start.toISOString().split("T")[0];
        const end_date = end.toISOString().split("T")[0];
        this.reNewPlanForm.date = [start_date, end_date];
      }
    },
    get_renew_table() {
      this.loading = true;
      var date = this.reNewPlanForm.date;
      var start_date = date[0];
      var end_date = date[1];
      var params = {
        start: start_date,
        end: end_date,
        renew_type: this.reNewPlanForm.plan,
      };
      get_renew_data_table(params)
        .then((res) => {
          this.renewTableData = res.data.data;
          this.loading = false;
          ElMessage({
            showClose: true,
            message: "获取续订数据成功",
            center: true,
            type: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
          console.error(err, "获取续订数据异常");
          ElMessage({
            showClose: true,
            message: "获取续订数据失败",
            center: true,
            type: "error",
          });
        });
    },
    handleCurrentChange(current_page) {
      this.filter_sub_user_table(current_page);
    },
    exportFile() {
      this.loading = true;
      let table_copy_userSubTableData = [];
      setTimeout(() => {
        table_copy_userSubTableData = [...this.copy_userSubTableData];
        this.copy_userSubTableData = [...this.copy_userSubTableData_hidden];
      }, 1000);

      setTimeout(() => {
        let table = document.getElementById("sub_table");
        let table_book = XLSX.utils.table_to_book(table);
        var table_write = XLSX.write(table_book, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
        try {
          FileSaver.saveAs(
            new Blob([table_write], {
              type: "application/octet-stream",
            }),
            "sub_table.xlsx"
          );
          ElMessage({
            showClose: true,
            message: "导出成功",
            center: true,
            type: "success",
          });
        } catch (e) {
          console.log("导出失败", e);
          ElMessage({
            showClose: true,
            message: "导出失败",
            center: true,
            type: "error",
          });
        } finally {
          this.copy_userSubTableData = table_copy_userSubTableData;

          this.loading = false;
        }
      }, 1000 * 2);
    },
    load_top_10_site_id_country_chart() {
      var chartDom = document.getElementById("top_10_site_country_chart");
      // 获取屏幕宽度
      var screen_width = document.body.clientWidth;
      var width = screen_width;
      var height = 600;
      chartDom.style.width = width + "px";
      chartDom.style.height = height + "px";
      var myChart = echarts.init(chartDom);
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: this.top_10_site_ids,
        },
        series: this.chart_series,
      };
      option && myChart.setOption(option);
      myChart.resize(width, height);
    },
    changeSubUserTabs() {
      if (this.sub_user_tab_index == "1") {
        this.flush_sub_user_chart();
      }
    },
    changeSubTabs() {
      if (this.sub_tab_index == "1") {
        this.flush_sub_rate_chart();
      }
    },
    changeNewDeviceTabs() {
      if (this.new_device_tab_index == "1") {
        this.flush_new_device_chart();
      }
    },
    flush_sub_user_chart() {
      this.load_pie_chart(
        "sub_status_pie_chart",
        "sub_status_pie",
        "订阅状态占比"
      );
      this.load_pie_chart(
        "sub_country_pie_chart",
        "country_pie",
        "前10地区占比"
      );
      this.load_pie_chart(
        "sub_campaign_pie_chart",
        "site_pie",
        "前10搜索来源占比"
      );
      this.load_pie_chart("sub_site_pie_chart", "campaign_pie", "广告组占比");
      this.load_top_10_site_id_country_chart();
    },
    flush_new_device_chart() {
      this.load_new_device_chart(
        "new_device_line_chart",
        "new",
        "新增设备曲线图"
      );
      this.load_new_device_chart(
        "sub_device_line_chart",
        "vip",
        "vip设备活跃曲线图"
      );
      this.load_new_device_chart(
        "free_device_line_chart",
        "free",
        "试用设备活跃曲线图"
      );
      this.load_new_device_chart(
        "vip_active_rate_line_chart",
        "vip_rate",
        "付费活跃率曲线图"
      );
    },
    flush_sub_rate_chart() {
      this.load_new_device_chart("sub_rate_line_chart", "sub", "订阅率曲线图");
      this.load_pie_chart("sub_pie_chart", "sub_pie", "订阅套餐占比");
      this.load_new_device_chart("money_line_chart", "money", "每日收入曲线图");
    },
    filter_sub_user_table(currentPage) {
      var tempUserData = [...this.userSubTableData];
      // 置换为第一页
      this.currentPage = currentPage;
      console.log("当前页数", this.currentPage);
      var start = (this.currentPage - 1) * 100;
      var end = start + 100;
      // 分页切分数据
      var subUserForm = this.subUserForm;
      this.copy_userSubTableData = tempUserData.filter((item) => {
        if (subUserForm.site_id !== "") {
          if (subUserForm.site_id != item.site_id) {
            return false;
          }
        }

        if (subUserForm.lang !== "") {
          if (subUserForm.lang != item.pub_country) {
            return false;
          }
        }

        if (subUserForm.is_free !== "") {
          console.log(
            subUserForm.is_free,
            "过滤状态",
            item.is_free,
            "客户状态"
          );
          if (subUserForm.is_free != item.is_free) {
            return false;
          }
        }

        if (subUserForm.priceCurrencyCode !== "") {
          if (subUserForm.priceCurrencyCode != item.priceCurrencyCode) {
            return false;
          }
        }

        if (subUserForm.country !== "") {
          if (subUserForm.country != item.country) {
            return false;
          }
        }

        if (subUserForm.shop_country !== "") {
          if (subUserForm.shop_country != item.shop_country) {
            return false;
          }
        }

        if (subUserForm.campaign_id !== "") {
          if (subUserForm.campaign_id != item.campaign_id) {
            return false;
          }
        }
        if (subUserForm.productId !== "") {
          if (subUserForm.productId != item.productId) {
            return false;
          }
        }
        if (subUserForm.sub_status !== "") {
          if (subUserForm.sub_status != item.sub_status) {
            return false;
          }
        }
        if (subUserForm.uid !== "") {
          if (subUserForm.uid != item.uid) {
            return false;
          }
        }
        return true;
      });
      this.total_sub_user = this.copy_userSubTableData.length;
      // todo 修改这个东西 数据
      this.copy_userSubTableData_hidden = this.copy_userSubTableData;
      this.copy_userSubTableData = this.copy_userSubTableData.slice(start, end);
    },
    query_sub_user_table() {
      this.loading = true;
      this.new_users = [];
      var date = this.subUserForm.date;
      var params = {};
      console.log(date, "查询");
      if (date != null) {
        var start_date = date[0];
        var end_date = date[1];
        params = {
          start: start_date,
          end: end_date,
          is_firstSub: this.subUserForm.is_firstSub,
        };
      }

      get_sub_user_table(params)
        .then((res) => {
          this.loading = false;
          this.lang_options = [];
          var langs = [];
          this.priceCurrencyCode_options = [];
          this.country_options = [];
          var priceCurrencyCodes = [];
          var countries = [];
          this.shop_country_options = [];
          var shop_countries = [];
          this.userSubTableData = res.data.userSubTableData;
          this.userSubTableData = this.userSubTableData.reverse();
          this.copy_userSubTableData = res.data.userSubTableData;

          this.filter_sub_user_table(1);

          // 查找userSubTableData列表里面 install_time  = '' 的数据, 把uid 传递给 new_users
          this.userSubTableData.forEach((item) => {
            if (item.install_time == "") {
              this.new_users.push(item.uid);
            }

            var shop_country_items = {
              value: item.shop_country,
              label: item.shop_country,
            };

            var lang_items = {
              label: item.pub_country,
              value: item.pub_country,
            };
            var priceCurrencyCode_items = {
              label: item.priceCurrencyCode,
              value: item.priceCurrencyCode,
            };
            var country_items = {
              label: item.country,
              value: item.country,
            };

            if (shop_countries.indexOf(item.shop_country) == -1) {
              shop_countries.push(item.shop_country);
              this.shop_country_options.push(shop_country_items);
            }

            if (langs.indexOf(item.pub_country) == -1) {
              langs.push(item.pub_country);
              this.lang_options.push(lang_items);
            }
            if (priceCurrencyCodes.indexOf(item.priceCurrencyCode) == -1) {
              priceCurrencyCodes.push(item.priceCurrencyCode);
              this.priceCurrencyCode_options.push(priceCurrencyCode_items);
            }
            if (countries.indexOf(item.country) == -1) {
              countries.push(item.country);
              this.country_options.push(country_items);
            }
          });
          this.sub_detail_data = res.data.sub_detail_data;
          this.top_10_site_ids = res.data.top_10_site_ids;
          this.chart_series = res.data.series;
          this.changeSubUserTabs();
          ElMessage({
            showClose: true,
            message: "加载数据完成",
            center: true,
            type: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          ElMessage({
            showClose: true,
            message: "网络异常",
            center: true,
            type: "error",
          });
        });
    },

    query_sub_user_table2() {
      this.loading = true;
      this.new_users = [];
      var date = this.subUserForm.date;
      var params = {};
      console.log(date, "查询");
      if (date != null) {
        var start_date = date[0];
        var end_date = date[1];
        params = {
          start: start_date,
          end: end_date,
          is_firstSub: this.subUserForm.is_firstSub,
          platform: this.sub_platform_map[this.sub_platform_name],
        };
      }

      get_sub_user_table2(params)
        .then((res) => {
          this.loading = false;
          this.lang_options = [];
          var langs = [];
          this.priceCurrencyCode_options = [];
          this.country_options = [];
          this.pay_source_options = [];
          var priceCurrencyCodes = [];
          var countries = [];
          this.shop_country_options = [];
          var shop_countries = [];
          console.log(res);
          this.userSubTableData = res.data.userSubTableData;
          this.userSubTableData = this.userSubTableData.reverse();
          this.copy_userSubTableData = res.data.userSubTableData;

          this.filter_sub_user_table(1);

          // 查找userSubTableData列表里面 install_time  = '' 的数据, 把uid 传递给 new_users
          this.userSubTableData.forEach((item) => {
            if (item.install_time == "") {
              this.new_users.push(item.uid);
            }

            var shop_country_items = {
              value: item.shop_country,
              label: item.shop_country,
            };

            var lang_items = {
              label: item.pub_country,
              value: item.pub_country,
            };
            var priceCurrencyCode_items = {
              label: item.priceCurrencyCode,
              value: item.priceCurrencyCode,
            };
            var country_items = {
              label: item.country,
              value: item.country,
            };

            if (shop_countries.indexOf(item.shop_country) == -1) {
              shop_countries.push(item.shop_country);
              this.shop_country_options.push(shop_country_items);
            }

            if (langs.indexOf(item.pub_country) == -1) {
              langs.push(item.pub_country);
              this.lang_options.push(lang_items);
            }
            if (priceCurrencyCodes.indexOf(item.priceCurrencyCode) == -1) {
              priceCurrencyCodes.push(item.priceCurrencyCode);
              this.priceCurrencyCode_options.push(priceCurrencyCode_items);
            }
            if (countries.indexOf(item.country) == -1) {
              countries.push(item.country);
              this.country_options.push(country_items);
            }
          });
          this.sub_detail_data = res.data.sub_detail_data;
          this.top_10_site_ids = res.data.top_10_site_ids;
          this.chart_series = res.data.series;
          this.changeSubUserTabs();
          ElMessage({
            showClose: true,
            message: "加载数据完成",
            center: true,
            type: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          ElMessage({
            showClose: true,
            message: "网络异常",
            center: true,
            type: "error",
          });
        });
    },

    query_sub_user_table3() {
      this.loading = true;
      this.new_users = [];
      var date = this.subUserForm.date;
      var params = {};
      console.log(date, "查询");
      if (date != null) {
        var start_date = date[0];
        var end_date = date[1];
        params = {
          start: start_date,
          end: end_date,
          is_firstSub: this.subUserForm.is_firstSub,
          platform: this.sub_platform_map[this.sub_platform_name],
        };
      }

      get_sub_user_table3(params)
        .then((res) => {
          this.loading = false;
          this.lang_options = [];
          var langs = [];
          this.priceCurrencyCode_options = [];
          this.country_options = [];
          var priceCurrencyCodes = [];
          var countries = [];
          this.shop_country_options = [];
          var shop_countries = [];
          console.log(res);
          this.userSubTableData = res.data.userSubTableData;
          this.pay_source_options = res.data.pay_source_options;
          this.userSubTableData = this.userSubTableData.reverse();
          this.copy_userSubTableData = res.data.userSubTableData;

          this.filter_sub_user_table(1);

          // 查找userSubTableData列表里面 install_time  = '' 的数据, 把uid 传递给 new_users
          this.userSubTableData.forEach((item) => {
            if (item.install_time == "") {
              this.new_users.push(item.uid);
            }

            var shop_country_items = {
              value: item.shop_country,
              label: item.shop_country,
            };

            var lang_items = {
              label: item.pub_country,
              value: item.pub_country,
            };
            var priceCurrencyCode_items = {
              label: item.priceCurrencyCode,
              value: item.priceCurrencyCode,
            };
            var country_items = {
              label: item.country,
              value: item.country,
            };

            if (shop_countries.indexOf(item.shop_country) == -1) {
              shop_countries.push(item.shop_country);
              this.shop_country_options.push(shop_country_items);
            }

            if (langs.indexOf(item.pub_country) == -1) {
              langs.push(item.pub_country);
              this.lang_options.push(lang_items);
            }
            if (priceCurrencyCodes.indexOf(item.priceCurrencyCode) == -1) {
              priceCurrencyCodes.push(item.priceCurrencyCode);
              this.priceCurrencyCode_options.push(priceCurrencyCode_items);
            }
            if (countries.indexOf(item.country) == -1) {
              countries.push(item.country);
              this.country_options.push(country_items);
            }
          });
          this.sub_detail_data = res.data.sub_detail_data;
          this.top_10_site_ids = res.data.top_10_site_ids;
          this.chart_series = res.data.series;
          this.changeSubUserTabs();
          ElMessage({
            showClose: true,
            message: "加载数据完成",
            center: true,
            type: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          ElMessage({
            showClose: true,
            message: "网络异常",
            center: true,
            type: "error",
          });
        });
    },

    query_sub_table() {
      this.loading = true;
      var date = this.subPlanForm.date;
      var start_date = date[0];
      var end_date = date[1];
      this.subPlanForm.start_date = start_date;
      this.subPlanForm.end_date = end_date;
      var subPlanForm = { ...this.subPlanForm };
      if (subPlanForm.mcc == "") {
        subPlanForm.mcc = [];
      }
      get_sub_table(subPlanForm)
        .then((res) => {
          this.loading = false;
          this.subTableData = res.data;
          this.changeSubTabs();
          ElMessage({
            showClose: true,
            message: "加载数据完成",
            center: true,
            type: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          ElMessage({
            showClose: true,
            message: "网络异常",
            center: true,
            type: "error",
          });
        });
    },
    flush_new_user_by_uid() {
      if (this.new_users.length == 0) {
        ElMessage({
          showClose: true,
          message: "无新用户需要刷新",
          center: true,
          type: "error",
        });
        return;
      }
      this.loading = true;
      flush_new_user_info_by_uid(this.new_users)
        .then((res) => {
          this.loading = false;
          ElMessage({
            showClose: true,
            message: res.data.msg,
            center: true,
            type: "success",
          });
          this.query_sub_user_table();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          ElMessage({
            showClose: true,
            message: "网络异常",
            center: true,
            type: "error",
          });
        });
    },
    load_new_device_chart(chart_id, type, title) {
      var dates = [];
      var data = [];
      var chartDom = document.getElementById(chart_id);
      // 获取屏幕宽度
      var screen_width = document.body.clientWidth;
      var width = screen_width;
      var height = 400;
      chartDom.style.width = width + "px";
      chartDom.style.height = height + "px";
      var myChart = echarts.init(chartDom);
      var option;
      if (type == "sub") {
        this.subTableData.forEach((item) => {
          if (item.date != "合计") {
            dates.push(item.date);
            data.push(item.sub_rate);
          }
        });
      } else if (type == "money") {
        this.subTableData.forEach((item) => {
          if (item.date != "合计") {
            dates.push(item.date);
            data.push(item.money);
          }
        });
      } else {
        this.deviceTableData.forEach((item) => {
          dates.push(item.date);
          if (type == "new") {
            data.push(item.new_device_num);
          } else if (type == "vip") {
            data.push(item.vip_device_num);
          } else if (type == "free") {
            data.push(item.free_device_num);
          } else if (type == "vip_rate") {
            data.push(item.vip_active_rate);
          }
        });
      }
      option = {
        title: {
          text: title,
          left: "center",
        },
        xAxis: {
          type: "category",
          data: dates,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: data,
            type: "line",
            markLine: {
              data: type == "sub" ? [] : [{ type: "average", name: "平均值" }],
            },
            itemStyle: {
              normal: {
                label: {
                  show: true,
                },
              },
            },
          },
        ],
      };
      console.log(option);
      option && myChart.setOption(option);
      myChart.resize(width, height);
    },
    load_pie_chart(chart_id, type, title) {
      var chartDom = document.getElementById(chart_id);
      // 获取屏幕宽度
      var screen_width = document.body.clientWidth;
      if (type != "sub_pie") {
        var width = screen_width / 2;
      } else {
        width = screen_width;
      }
      var height = 400;
      var data = [];
      chartDom.style.width = width + "px";
      chartDom.style.height = height + "px";
      var myChart = echarts.init(chartDom);
      var option;
      if (type == "sub_pie") {
        this.subTableData.some((item) => {
          if (item.date == "合计") {
            data.push({ value: item.plan12_num, name: "年套餐" });
            data.push({ value: item.plan1_num, name: "月套餐" });
            data.push({ value: item.plan3_num, name: "季套餐" });
            data.push({ value: item.plan6_num, name: "半年套餐" });
            return true;
          }
        });
      } else if (type == "country_pie") {
        // 统计this.userSubTableData 每个country 的个数
        var country_count = {};
        this.userSubTableData.forEach((item) => {
          if (country_count[item.country] == undefined) {
            country_count[item.country] = 1;
          } else {
            country_count[item.country] += 1;
          }
        });
        //根据value 从大到小 排序country_count
        var country_count_arr = [];
        for (var key in country_count) {
          country_count_arr.push({
            name: key,
            value: country_count[key],
          });
        }
        country_count_arr.sort((a, b) => {
          return b.value - a.value;
        });
        // 只取前10个
        country_count_arr = country_count_arr.slice(0, 10);
        country_count_arr.forEach((item) => {
          data.push({ value: item.value, name: item.name });
        });
      } else if (type == "site_pie") {
        // 统计this.userSubTableData 每个site_id 的个数
        var site_id_count = {};
        this.userSubTableData.forEach((item) => {
          if (item.site_id != "null") {
            if (site_id_count[item.site_id] == undefined) {
              site_id_count[item.site_id] = 1;
            } else {
              site_id_count[item.site_id] += 1;
            }
          }
        });
        //根据value 从大到小 排序site_id_count
        var site_id_count_arr = [];
        for (var site_id_key in site_id_count) {
          site_id_count_arr.push({
            name: site_id_key,
            value: site_id_count[site_id_key],
          });
        }
        site_id_count_arr.sort((a, b) => {
          return b.value - a.value;
        });
        // 只取前10个
        site_id_count_arr = site_id_count_arr.slice(0, 10);
        site_id_count_arr.forEach((item) => {
          data.push({ value: item.value, name: item.name });
        });
      } else if (type == "campaign_pie") {
        // 统计this.userSubTableData 每个campaign_id 的个数
        var campaign_id_count = {};
        this.userSubTableData.forEach((item) => {
          if (item.campaign_id != "null") {
            if (campaign_id_count[item.campaign_id] == undefined) {
              campaign_id_count[item.campaign_id] = 1;
            } else {
              campaign_id_count[item.campaign_id] += 1;
            }
          }
        });
        //根据value 从大到小 排序campaign_id_count
        var campaign_id_count_arr = [];
        for (var campaign_id_key in campaign_id_count) {
          campaign_id_count_arr.push({
            name: campaign_id_key,
            value: campaign_id_count[campaign_id_key],
          });
        }
        campaign_id_count_arr.sort((a, b) => {
          return b.value - a.value;
        });
        // 只取前10个
        campaign_id_count_arr = campaign_id_count_arr.slice(0, 10);
        campaign_id_count_arr.forEach((item) => {
          data.push({ value: item.value, name: item.name });
        });
      } else if (type == "sub_status_pie") {
        // 统计this.userSubTableData 每个sub_status的个数
        var sub_status_count = {};
        this.userSubTableData.forEach((item) => {
          if (item.sub_status != "null") {
            if (sub_status_count[item.sub_status] == undefined) {
              sub_status_count[item.sub_status] = 1;
            } else {
              sub_status_count[item.sub_status] += 1;
            }
          }
        });
        // 根据value 从大到小 排序sub_status_count
        var sub_status_count_arr = [];
        for (var sub_status_key in sub_status_count) {
          sub_status_count_arr.push({
            name: sub_status_key,
            value: sub_status_count[sub_status_key],
          });
        }
        sub_status_count_arr.sort((a, b) => {
          return b.value - a.value;
        });
        sub_status_count_arr.forEach((item) => {
          data.push({ value: item.value, name: item.name });
        });
      }

      option = {
        title: {
          text: title,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "订阅套餐",
            type: "pie",
            radius: "50%",
            data: data,
            label: {
              normal: {
                show: true,
                formatter: "{b}: {c}个 ( {d}% )",
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      console.log(option);
      option && myChart.setOption(option);
      myChart.resize(width, height);
    },
    query_new_device_table() {
      this.get_configs();
      var date = this.newDeviceForm.date;
      var start_date = date[0];
      var end_date = date[1];
      this.newDeviceForm.start_date = start_date;
      this.newDeviceForm.end_date = end_date;
      this.loading = true;
      var newDeviceForm = { ...this.newDeviceForm };
      if (newDeviceForm.mcc == "") {
        newDeviceForm.mcc = [];
      }
      get_new_device_table(newDeviceForm)
        .then((res) => {
          console.log(res.data);
          this.deviceTableData = res.data;
          this.changeNewDeviceTabs();
          this.loading = false;
          ElMessage({
            showClose: true,
            message: "加载数据完成",
            center: true,
            type: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          ElMessage({
            showClose: true,
            message: "网络异常",
            center: true,
            type: "error",
          });
        });
    },
    get_configs() {
      let configs = null;
      configs = sessionStorage.getItem("configs");
      if (configs) {
        configs = JSON.parse(configs);
        this.platform_list = configs.platform_options;
        this.country_list = configs.mcc_options;
        this.site_id_list = configs.site_id_options_group;
        this.campaign_id_list = configs.campaign_id_options_group;
      } else {
        get_filter_options()
          .then((res) => {
            console.log(res.data);
            configs = res.data;
            this.platform_list = configs.platform_options;
            this.country_list = configs.mcc_options;
            this.site_id_list = configs.site_id_options_group;
            this.campaign_id_list = configs.campaign_id_options_group;
            var str_configs = JSON.stringify(configs);
            sessionStorage.setItem("configs", str_configs);
            ElMessage({
              showClose: true,
              message: "刷新搜索参数成功",
              center: true,
              type: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            ElMessage({
              showClose: true,
              message: "加载配置失败",
              center: true,
              type: "error",
            });
          });
      }
    },
    get_income_table() {
      this.query_income_loading = true;
      if (this.income_form.date !== null) {
        this.income_form.start_date = this.income_form.date[0];
        this.income_form.end_date = this.income_form.date[1];
      } else {
        ElMessage({
          showClose: true,
          message: "选择时间范围",
          center: true,
          type: "error",
        });
        return;
      }

      var params = {
        start_time: this.income_form.start_date,
        end_time: this.income_form.end_date,
      };
      get_income_tables(params).then((res) => {
        this.income_data_table = res.data;
        ElMessage({
          showClose: true,
          message: "加载数据完成",
          center: true,
          type: "success",
        });
        this.query_income_loading = false;
      });
    },
    get_income_table2() {
      this.query_income_loading2 = true;
      if (this.income_form.date !== null) {
        this.income_form.start_date = this.income_form.date[0];
        this.income_form.end_date = this.income_form.date[1];
      } else {
        ElMessage({
          showClose: true,
          message: "选择时间范围",
          center: true,
          type: "error",
        });
        return;
      }

      var params = {
        start_time: this.income_form.start_date,
        end_time: this.income_form.end_date,
        platform: this.sub_platform_map[this.sub_platform_name],
        p: this.project
      };
      if (params.platform == "appstore") {
        // 走接口1
        get_income_tables(params)
          .then((res) => {
            this.income_data_table2 = res.data;
            ElMessage({
              showClose: true,
              message: "加载数据完成",
              center: true,
              type: "success",
            });
            this.query_income_loading2 = false;
          })
          .catch((error) => {
            this.query_income_loading2 = false;
            console.log(error);
          });
      } else {
        // 走接口2
        get_income_tables2(params)
          .then((res) => {
            this.income_data_table2 = res.data;
            ElMessage({
              showClose: true,
              message: "加载数据完成",
              center: true,
              type: "success",
            });
            this.query_income_loading2 = false;
          })
          .catch((error) => {
            this.query_income_loading2 = false;
            console.log(error);
          });
      }
    },
    get_income_table3() {
      this.query_income_loading2 = true;
      if (this.income_form.date !== null) {
        this.income_form.start_date = this.income_form.date[0];
        this.income_form.end_date = this.income_form.date[1];
      } else {
        ElMessage({
          showClose: true,
          message: "选择时间范围",
          center: true,
          type: "error",
        });
        return;
      }

      var params = {
        start_time: this.income_form.start_date,
        end_time: this.income_form.end_date,
        platform: this.sub_platform_map[this.sub_platform_name],
      };
      if (params.platform == "appstore") {
        // 走接口1
        get_income_tables(params)
          .then((res) => {
            this.income_data_table2 = res.data;
            ElMessage({
              showClose: true,
              message: "加载数据完成",
              center: true,
              type: "success",
            });
            this.query_income_loading2 = false;
          })
          .catch((error) => {
            this.query_income_loading2 = false;
            console.log(error);
          });
      } else {
        // 走接口2
        get_income_tables3(params)
          .then((res) => {
            this.income_data_table2 = res.data;
            ElMessage({
              showClose: true,
              message: "加载数据完成",
              center: true,
              type: "success",
            });
            this.query_income_loading2 = false;
          })
          .catch((error) => {
            this.query_income_loading2 = false;
            console.log(error);
          });
      }
    },
    flush_search_value() {
      sessionStorage.removeItem("configs");
      this.get_configs();
    },
    onSubmit() {
      ElMessage({
        showClose: true,
        message: "开发中...",
        center: true,
        type: "error",
      });
    },
  },
  mounted() {
    const token = get_token();
    if (token == "3282d437bde390612023c5237be0fa6d") {
      this.query_new_device_table();
    }
  },
  watch: {
    project(newProject, oldProject) {
      if (newProject == "LineVPN") {
        this.sub_platform_list = [
        { tag: "App Store", value: "App Store" },
        ]
        this.sub_platform_name = "App Store"
      }
      else {
        this.sub_platform_list = [
          { tag: "All", value: "All" },
          { tag: "App Store", value: "App Store" },
          { tag: "Google Play", value: "Google Play" },
          { tag: "官网", value: "官网" },
          { tag: "PayPal", value: "PayPal" },
          { tag: "Stripe", value: "Stripe" },
          { tag: "Beamo", value: "Beamo" },
          { tag: "Payssion", value: "Payssion" },
        ]
      }
      console.log('project:', oldProject, '->', newProject);
    },
  },
};
</script>
  
  
  
<style>
.base_layout {
  height: 100%;
  margin: 0 auto;
  padding-top: 40px;
}

.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.success {
  color: green;
}

.danger {
  color: red;
}

.normal {
  color: black;
}
</style>